

.main-bg {
    @apply dark:bg-d-background bg-l-background;
}

.secondary-bg {
    @apply dark:bg-d-background-2 bg-l-background-2;
}

.third-bg {
    @apply dark:bg-d-background-3 bg-l-background-3;
}

.fourth-bg {
    @apply dark:bg-d-background-4 bg-l-background-4;
}

.main-border {
    @apply border-[1px] dark:border-slate-700 border-gray-300;
}

.normal-border {
    @apply border-[1px] dark:border-d-main-2 border-l-main-2;
}

.flex-center {
    @apply flex items-center justify-center;
}

.flex-start {
   @apply flex items-center justify-start;
}

.menu-hover {
    @apply dark:hover:bg-slate-800 hover:bg-gray-200;
}

.text-secondary {
    @apply dark:text-d-main-2 text-l-main-2;
}

.text-third {
    @apply dark:text-slate-400 text-gray-600;
}

.w-line {
    @apply bg-d-background-2 dark:bg-l-background-2 w-full h-[1px] opacity-20;
}

.mini-w-line {
    @apply bg-d-background-2 dark:bg-l-background-2 opacity-20 h-[1px] w-[24px];
}

.page-content {
    @apply max-w-[1367px] mx-auto mt-6 lg:mt-20 px-4 lg:px-8 pb-10;
}

