@import "btn.css";
@import "loader.css";
@import "global.css";
@import "../../assets/fonts/dana/fontiran.css";

@import '../../../node_modules/@fontsource/inter/100.css';
@import '../../../node_modules/@fontsource/inter/200.css';
@import '../../../node_modules/@fontsource/inter/300.css';
@import '../../../node_modules/@fontsource/inter/400.css';
@import '../../../node_modules/@fontsource/inter/500.css';
@import '../../../node_modules/@fontsource/inter/600.css';
@import '../../../node_modules/@fontsource/inter/700.css';
@import '../../../node_modules/@fontsource/inter/800.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/*@font-face {*/
/*  font-family: "Vazir";*/
/*  src: url("../../assets/fonts/dana-fanum-regular.woff2");*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'dana', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}


.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: #0A0C17 !important;
}

.custom-calendar .rmdp-month-picker{
  background-color: #0A0C17 !important;
}

.custom-calendar .rmdp-year-picker{
  background-color: #0A0C17 !important;
}

.rmdp-analog-clock{
  border: none !important;
  outline: 5px solid #09589e;
}

.rmdp-calendar-container-mobile {
  z-index: 9999999999999999 !important;
}

@media screen and (max-width: 1050px) {
  .rmdp-time-picker input {
    pointer-events:none;
  }

  .rmdp-arrow {
    width: 8px !important;
    height: 8px !important;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}


/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5e6673;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e6673;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

