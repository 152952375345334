/*
    Action button loader
 */
.btn-loader {
    width:24px;
    height:24px;
    border-radius:50%;
    border:4px solid;
    animation:s1 1s infinite;
}

.btn-loader-dark{
    border-color:#0A0C17 #0000;
}

.btn-loader-light{
    border-color: white #0000;
}

@keyframes s1 {to{transform: rotate(.5turn)}}

/*
    mini button loader
 */
.mini-loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 4px solid #0000;
    position: relative;
    animation: s4 1s infinite linear;
}
.mini-loader:before,
.mini-loader:after {
    content: "";
    position: absolute;
    inset:-4px;
    border-radius: 50%;
    border:inherit;
    animation:inherit;
    animation-duration: 2s;
}
.mini-loader:after {
    animation-duration: 4s;
}

@keyframes s4 {
    100% {transform: rotate(1turn)}
}

/*
    big loader
 */

.big-loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 8px solid #0000;
    border-right-color: #0186D097;
    position: relative;
    animation: s5 1s infinite linear;
}
.big-loader:before,
.big-loader:after {
    content: "";
    position: absolute;
    inset:-8px;
    border-radius: 50%;
    border:inherit;
    animation:inherit;
    animation-duration: 2s;
}
.big-loader:after {
    animation-duration: 4s;
}

@keyframes s5 {
    100% {transform: rotate(1turn)}
}

@keyframes fading {
    0% {
        opacity: 0.1;
    }
    100%{
        opacity: 1;
    }
}

.fadingAnim {
    animation: fading 1s infinite linear;
}

@keyframes scaleoutAnim {
    0% {
        transform: translateX(400px);
        opacity: 0.5;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

.scaleout {
    animation: scaleoutAnim 0.3s forwards linear;
}
